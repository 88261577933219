import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Component/Navbar";
import Home from "./Component/Home";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import About from "./Component/About";
import Single from "./Component/Single";
import ContactUs from "./Component/ContactUs";
import TermCondition from "./Component/TermCondition";
import Sidebar from "./Component/Sidebar";
import { createContext, useState } from "react";

export const SomeContext = createContext(true);

function App() {
  const [isSideOpen, setIsSideOpen] = useState(false);
  return (
    <SomeContext.Provider value={{ isSideOpen, setIsSideOpen }}>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <Sidebar />
        <div className="p-5 sm:ml-64 mt-12">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/about" element={<About />} />
            <Route path="/single" element={<Single />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/term-condition" element={<TermCondition />} />
          </Routes>
        </div>
      </div>
    </SomeContext.Provider>
  );
}

export default App;
