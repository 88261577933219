import React, { useState } from "react";
import Loader from "./Loader";

export default function PrivacyPolicy() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 bg-[#023047] text-gray-300 rounded-lg shadow-lg my-3">
        <h1 className="text-2xl font-bold text-center">Privacy Policy</h1>

        <h6 className="font-bold mb-3 mt-6">1. Introduction</h6>
        <p className="my-3">
          This privacy policy explains how Artistry Arts Site collects, uses,
          and protects any information that you provide when you use this
          website.
        </p>

        <h6 className="font-bold mb-3 mt-6">2. Information We Collect</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            We may collect personal information such as your name, email
            address, and payment details when you register for an account or
            make a purchase.
          </li>
          <li className="my-2">
            We also collect non-personal information such as your browser type,
            IP address, and browsing behavior on our website.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">3. How We Use Your Information</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">To provide and improve our services.</li>
          <li className="my-2">
            To process transactions and send periodic emails regarding your
            order or other products and services.
          </li>
          <li className="my-2">
            To improve our website based on the information and feedback we
            receive from you.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">4. Information Protection</h6>
        <p className="my-3">
          We implement a variety of security measures to maintain the safety of
          your personal information. All transactions are processed through a
          secure payment gateway provider and are not stored or processed on our
          servers.
        </p>

        <h6 className="font-bold mb-3 mt-6">5. Cookies</h6>
        <p className="my-3">
          We use cookies to understand and save your preferences for future
          visits and compile aggregate data about site traffic and site
          interactions.
        </p>

        <h6 className="font-bold mb-3 mt-6">6. Third-Party Disclosure</h6>
        <p className="my-3">
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information unless we provide users with
          advance notice. This does not include website hosting partners and
          other parties who assist us in operating our website, conducting our
          business, or serving our users, so long as those parties agree to keep
          this information confidential.
        </p>

        <h6 className="font-bold mb-3 mt-6">7. Your Consent</h6>
        <p className="my-3">
          By using our site, you consent to our website privacy policy.
        </p>

        <h6 className="font-bold mb-3 mt-6">
          8. Changes to Our Privacy Policy
        </h6>
        <p className="my-3">
          We reserve the right to update or change our privacy policy at any
          time. Any changes will be posted on this page.
        </p>

        <h6 className="font-bold mb-3 mt-6">Contact Us</h6>
        <p className="my-3">
          If you have any questions regarding our terms and conditions, about
          us, or privacy policy, please contact us at support@artistryarts.site.
        </p>
      </section>
    </>
  );
}
