import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SomeContext } from "../App";
import {
  FaHouse,
  FaCircleInfo,
  FaFileContract,
  FaRegAddressBook,
} from "react-icons/fa6";
import { MdSecurity } from "react-icons/md";
export default function Sidebar() {
  const location = useLocation();
  const { isSideOpen, setIsSideOpen } = useContext(SomeContext);
  const currentPath = location.pathname.split("/")[1];

  const [activeLink, setActiveLink] = useState(
    currentPath !== "" ? currentPath : "home"
  );

  const handleLinkClick = (link) => {
    setIsSideOpen(false);
    setActiveLink(link);
  };

  return (
    <>
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-[#023047] border-r border-gray-200 ${
          !isSideOpen ? "-translate-x-full" : ""
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-[#023047]">
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to="/"
                className={`flex items-center p-2 rounded-lg hover:bg-sky-100 hover:text-gray-800 hover:ms-3 group ${
                  activeLink === "home" ? "bg-sky-200" : "text-white"
                }`}
                onClick={() => handleLinkClick("home")}
              >
                <FaHouse className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900" />
                <span className="ms-3">Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                className={`flex items-center p-2 rounded-lg hover:bg-sky-100 hover:text-gray-800 hover:ms-3 group ${
                  activeLink === "privacy-policy" ? "bg-sky-200" : "text-white"
                }`}
                onClick={() => handleLinkClick("privacy-policy")}
              >
                <MdSecurity className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900" />
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Privacy Policy
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`flex items-center p-2 rounded-lg hover:bg-sky-100 hover:text-gray-800 hover:ms-3 group ${
                  activeLink === "about" ? "bg-sky-200" : "text-white"
                }`}
                onClick={() => handleLinkClick("about")}
              >
                <FaCircleInfo className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900" />
                <span className="flex-1 ms-3 whitespace-nowrap">About</span>
              </Link>
            </li>
            <li>
              <Link
                to="/term-condition"
                className={`flex items-center p-2 rounded-lg hover:bg-sky-100 hover:text-gray-800 hover:ms-3 group ${
                  activeLink === "term-condition" ? "bg-sky-200" : "text-white"
                }`}
                onClick={() => handleLinkClick("term-condition")}
              >
                <FaFileContract className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900" />
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Term Condition
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                className={`flex items-center p-2 rounded-lg hover:bg-sky-100 hover:text-gray-800 hover:ms-3 group ${
                  activeLink === "contact-us" ? "bg-sky-200" : "text-white"
                }`}
                onClick={() => handleLinkClick("contact-us")}
              >
                <FaRegAddressBook className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900" />
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Contact Us
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
}
