import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    <div className="relative w-[200px] h-[250px] rounded-lg  overflow-hidden flex flex-col items-center justify-center shadow-[20px_20px_60px_#bebebe,-20px_-20px_60px_#ffffff]  mx-auto">
      <div className="absolute  left-[5px] w-[190px] h-[240px] z-10 bg-white/95 backdrop-blur-lg rounded-md overflow-hidden outline outline-2 outline-white flex flex-col items-center justify-center">
        <img className="w-full" src={data.logo} alt={data.title} />
        <p className="text-xl font-bold text-gray-900 text-center">
          {data.title}
        </p>
        <div className="button-wrapper my-5">
          <Link
            to={`/single?slug=${data.slug}`}
            className="relative text-white bg-gradient-to-br from-cyan-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-xl py-2 mx-auto text-center me-2 p-4 overflow-hidden"
          >
            <span className="relative z-10">Play</span>
          </Link>
        </div>
      </div>
      <div className="absolute z-0 left-1/2 w-[200px] h-[150px] rounded-full bg-[#023047] opacity-100 blur-md animate-blob-bounce"></div>
    </div>
  );
}
