import React, { useState } from "react";
import GamesData from "../Games.json";
import { useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import Loader from "./Loader";
import GameCard from "./GameCard";
import Games from "../Games.json";

export default function Single() {
  const [searchParams] = useSearchParams();
  const [isLoad, setIsLoad] = useState(true);
  const slug = searchParams.get("slug");
  const game = GamesData.find((game) => game.slug === slug);

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  setTimeout(() => {
    setIsLoad(false);
  }, 1000);

  return isLoad ? (
    <Loader />
  ) : (
    <section className="mx-3">
      <h1 className="text-center text-[#023047] text-3xl font-bold my-5">
        Title : {game?.title}
      </h1>
      <div className="w-full rounded-lg h-[650px] block shadow-lg bg-[#023047] hover:bg-cyan-900 overflow-hidden my-5">
        <iframe
          src={game?.gameurl}
          className="w-full h-full overflow-hidden"
          title={game?.title}
        ></iframe>
      </div>

      <section className="flex items-center justify-center md:px-8 px-2 py-8">
        <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 w-full">
          {Games.slice(0, 4).map((data, index) => (
            <GameCard key={index} data={data} />
          ))}
        </div>
      </section>

      {game.description && (
        <div
          className="p-6 bg-[#023047]  text-gray-300 shadow-lg rounded-lg  my-3"
          dangerouslySetInnerHTML={createMarkup(game.description)}
        ></div>
      )}

      <section className="flex items-center justify-center md:px-8 px-2 py-8">
        <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 w-full">
          {Games.slice(4, 8).map((data, index) => (
            <GameCard key={index} data={data} />
          ))}
        </div>
      </section>

      {game.sec_desc && (
        <div
          className="p-6 bg-[#023047]  text-gray-300 shadow-lg rounded-lg  my-3"
          dangerouslySetInnerHTML={createMarkup(game.sec_desc)}
        ></div>
      )}

      <section className="flex items-center justify-center md:px-8 px-2 py-8">
        <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 w-full">
          {Games.slice(8).map((data, index) => (
            <GameCard key={index} data={data} />
          ))}
        </div>
      </section>
    </section>
  );
}
