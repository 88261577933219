import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 bg-[#023047] text-gray-300 rounded-lg shadow my-3">
        <h1 className="text-2xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>Artistry Arts!</b>
        </p>
        <p className="my-3">
          At Artistry Arts, we celebrate creativity and artistic expression in
          all its forms. Our mission is to provide artists, both aspiring and
          experienced, with the resources, inspiration, and community they need
          to thrive and create their best work.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Artistry Arts was born out of a passion for the arts and a desire to
          support artists in their creative journeys. We believe that art has
          the power to transform lives and communities, and we are dedicated to
          fostering a vibrant and inclusive artistic community.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team is made up of artists, art educators, and industry
          professionals who are passionate about the arts. We bring a diverse
          range of expertise and perspectives to our work, ensuring that we
          offer high-quality resources and support to our community.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Art Classes and Workshops:</b> We offer a variety of classes and
            workshops in different mediums, designed to help artists of all
            skill levels develop their techniques and express their creativity.
          </li>
          <li className="my-2">
            <b>Art Supplies:</b> Our online store features a curated selection
            of high-quality art supplies to meet the needs of artists working in
            all mediums.
          </li>
          <li className="my-2">
            <b>Artist Community:</b> Join our community of artists to share your
            work, get feedback, and connect with others who share your passion
            for the arts.
          </li>
          <li className="my-2">
            <b>Exhibitions and Events:</b> Participate in exhibitions and events
            to showcase your work and engage with the broader art community.
          </li>
        </ul>

        <p className="my-2">
          Thank you for being a part of Artistry Arts. We are excited to support
          your artistic journey and help you achieve your creative goals.
        </p>
      </section>
    </>
  );
}
